<template>
  <div id="link-qrcode" v-cloak>
    <a-spin :spinning="isLoading" size="large">
      <div v-if="hasEsim">
        <van-nav-bar safe-area-inset-top :title="$t('qrcode.title')">
          <template #right>
            <van-popover
              v-model="showPopover"
              trigger="click"
              placement="bottom-end"
              :actions="actions"
              @select="handleSelectLang"
            >
              <template #reference>
                <span class="lang">{{ $t("qrcode.lang") }}</span>
              </template>
            </van-popover>
          </template>
        </van-nav-bar>
        <div class="container">
          <div
            class="instruction"
            @click="
              $router.push({
                name: 'Instruction',
                query: { lang: $i18n.locale },
              })
            "
          >
            <span>{{ $t("qrcode.guide") }}</span>
            <van-icon name="arrow" color="#773de1" size="18px" />
          </div>
          <div class="qrcode-list">
            <div class="qrcode-header" v-if="hasEsim">
              <div class="title">
                {{ orderInfo.info.plan_name }} {{ orderInfo.info.days }}
                {{ $t("qrcode.days") }}<span>×{{ orderInfo.list.length }}</span>
              </div>
              <div class="end">
                {{ $t("qrcode.time", { value: orderInfo.info.expired_time }) }}
              </div>
            </div>
            <div
              class="qrcode-item"
              v-for="(item, index) in orderInfo.list"
              :key="index"
            >
              <van-divider />
              <div class="subtitle" v-if="item.qrcode">
                {{ $t("qrcode.subtitle_1") }}
              </div>
              <div class="qrcode" v-if="item.qrcode">
                <a
                  v-if="installShow"
                  class="qrcode-link"
                  :href="
                    `https://esimsetup.apple.com/esim_qrcode_provisioning?carddata=${item.active_code}`
                  "
                >
                  iOS 17.4+ {{ $t("qrcode.install") }}
                </a>
                <vue-qr :text="item.qrcode" :size="250" :margin="20"></vue-qr>
              </div>
              <div class="subtitle" v-if="item.smdp_address">
                {{ $t("qrcode.subtitle_2") }}
              </div>
              <div
                class="subtitle"
                v-if="item.smdp_address && item.activation_code"
              >
                IOS
              </div>
              <van-row
                v-if="item.smdp_address"
                gutter="20"
                type="flex"
                justify="center"
                align="center"
                class="info"
              >
                <van-col class="label" span="8">{{
                  $t("qrcode.label_1")
                }}</van-col>
                <van-col
                  class="value copy"
                  @click="handleCopy"
                  :data-clipboard-text="item.smdp_address"
                  span="16"
                  ><span>{{ item.smdp_address }}</span>
                  <span class="iconfont icon-copy"></span
                ></van-col>
              </van-row>
              <van-row
                v-if="item.activation_code"
                gutter="20"
                type="flex"
                justify="center"
                align="center"
                class="info"
              >
                <van-col class="label" span="8">{{
                  $t("qrcode.label_2")
                }}</van-col>
                <van-col
                  class="value copy"
                  @click="handleCopy"
                  :data-clipboard-text="item.activation_code"
                  span="16"
                  ><span>{{ item.activation_code }}</span>
                  <span class="iconfont icon-copy"></span
                ></van-col>
              </van-row>
              <div
                class="subtitle"
                v-if="item.smdp_address && item.activation_code"
              >
                Android
              </div>
              <van-row
                v-if="item.smdp_address && item.activation_code"
                gutter="20"
                type="flex"
                justify="center"
                align="center"
                class="info"
              >
                <van-col class="label" span="8"
                  >{{ $t("qrcode.label_1") }}<br />{{
                    $t("qrcode.label_2")
                  }}</van-col
                >
                <van-col
                  class="value copy"
                  @click="handleCopy"
                  :data-clipboard-text="item.active_code"
                  span="16"
                  ><span>{{ item.active_code }}</span>
                  <span class="iconfont icon-copy"></span
                ></van-col>
              </van-row>
              <van-row
                gutter="20"
                type="flex"
                justify="center"
                align="center"
                class="info"
              >
                <van-col class="label" span="8">{{
                  $t("qrcode.label_3")
                }}</van-col>
                <van-col
                  class="value copy"
                  @click="handleCopy"
                  :data-clipboard-text="item.iccid"
                  span="16"
                  ><span>{{ item.iccid }}</span>
                  <span class="iconfont icon-copy"></span
                ></van-col>
              </van-row>
              <van-row
                gutter="20"
                type="flex"
                justify="center"
                align="center"
                class="info"
              >
                <van-col class="label" span="8">{{
                  $t("qrcode.label_4")
                }}</van-col>
                <van-col
                  class="value copy"
                  @click="handleCopy"
                  :data-clipboard-text="item.oid"
                  span="16"
                  ><span>{{ item.oid }}</span>
                  <span class="iconfont icon-copy"></span
                ></van-col>
              </van-row>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="order-empty flex">
        <a-empty :image="require('@/assets/mydata_none.png')">
          <span slot="description" class="empty-text">
            {{ $t("qrcode.empty") }}
          </span>
        </a-empty>
      </div>
    </a-spin>
  </div>
</template>
<script>
import VueQr from "vue-qr";
import Clipboard from "clipboard";
import { message } from "ant-design-vue";
import { Base64 } from "js-base64";
import { EsimQRCodeAPI } from "../api/api";
export default {
  name: "QRCode",
  components: { VueQr },
  data() {
    return {
      installShow: false,
      isLoading: false,
      hasEsim: false,
      showPopover: false,
      params: { lang: null, recordId: null, orderId: null },
      orderInfo: {},
      actions: [
        { id: 0, text: "中文简体", lang: "zh-cn", className: "lang" },
        { id: 1, text: "繁體中文", lang: "zh-tw", className: "lang" },
        { id: 2, text: "English", lang: "en", className: "lang" },
      ],
    };
  },
  mounted() {
    this.handleURLDecode();
    this.init();
    this.installShow = this.isIOSVersionAtLeast(17.4)
  },
  methods: {
    getIOSVersion() {
      const userAgent = navigator.userAgent;
      const iosVersionMatch = userAgent.match(/OS (\d+_\d+)/);

      if (iosVersionMatch) {
        // Convert the version string to a number for comparison
        const versionString = iosVersionMatch[1].replace("_", ".");
        return parseFloat(versionString);
      }
      return null; // Not an iOS device
    },
    isIOSVersionAtLeast(minVersion) {
      const iosVersion = this.getIOSVersion();
      if (iosVersion !== null) {
        return iosVersion >= minVersion;
      }
      return false;
    },
    async init() {
      try {
        this.isLoading = true;
        const { data: info } = await EsimQRCodeAPI(this.params);
        this.isLoading = false;
        if (info.list.length <= 0) return (this.hasEsim = false);
        this.orderInfo = info;
        this.hasEsim = true;
      } catch (error) {
        this.isLoading = false;
      }
    },
    handleSelectLang({ lang, id }) {
      this.$i18n.locale = lang;
      this.params.lang = id;
      this.init();
    },
    handleURLDecode() {
      const URL = decodeURIComponent(Base64.decode(window.location.search));
      const urlParams = new URLSearchParams(URL);
      urlParams.forEach((value, key) => {
        this.params[key] = decodeURIComponent(value);
      });
      this.$i18n.locale =
        this.params.lang === "0"
          ? "zh-cn"
          : this.params.lang == "1"
          ? "zh-tw"
          : "en";
    },
    handleCopy() {
      let clipboard = new Clipboard(".copy");
      clipboard.on("success", (e) => {
        message.success(this.$t("mine.flow_3"));
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        message.warning(this.$t("mine.flow_4"));
        clipboard.destroy();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#link-qrcode {
  background-color: #f2f3f6;
  padding-bottom: 40px;
  .lang {
    color: #999999;
  }
  .order-empty {
    height: 80vh;
  }
  .qrcode-list {
    margin-top: 20px;
    padding: 20px 16px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgba(23, 59, 92, 0.08);
    border-radius: 6px;
    .qrcode-header {
      .title {
        font-size: 18px;
        font-weight: 700;
        color: #222;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-left: 10px;
          color: $color;
          user-select: none;
        }
      }
      .end {
        margin-top: 10px;
        color: #e80000;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        user-select: none;
      }
    }
    .qrcode-item {
      color: #5d5d5d;
      .qrcode {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
        user-select: none;
        .qrcode-link {
          color: $color;
          font-weight: bold;
          text-decoration: underline;
        }
      }
      .subtitle {
        color: #222;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
        user-select: none;
      }
      .info {
        font-size: 14px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        .label {
          font-size: 13.5px;
          user-select: none;
        }
        .value {
          word-break: break-all;
        }
        .copy {
          word-break: break-all;
          background-color: #f2f3f6;
          border-radius: 3px;
          padding: 10px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .icon-copy {
            margin-left: 6px;
          }
        }
      }
    }
  }
  .instruction {
    margin-top: 20px;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    color: $color;
    cursor: pointer;
    user-select: none;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgba(23, 59, 92, 0.08);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
